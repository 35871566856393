<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button></app-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="ion-padding-bottom ion-padding-end ion-padding-start">
    <h1 class="ion-no-margin">Cookies Policy</h1>
    <p class="standard"><small>Last updated: February 22, 2023</small></p>
    <p class="standard">This Cookies Policy explains what Cookies are and how We use them. You should read this policy
        so You can understand what type of cookies We use, or the information We collect using Cookies and how that
        information is used.</p>
    <p class="standard">Cookies do not typically contain any information that personally identifies a user, but personal
        information that we store about You may be linked to the information stored in and obtained from Cookies. For
        further information on how We use, store and keep your personal data secure, see our Privacy Policy.</p>
    <p class="standard">We do not store sensitive personal information, such as mailing addresses, account passwords,
        etc. in the Cookies We use.</p>
    <h2>Interpretation and Definitions</h2>
    <h3>Interpretation</h3>
    <p class="standard">The words of which the initial letter is capitalized have meanings defined under the following
        conditions. The following definitions shall have the same meaning regardless of whether they appear in singular
        or in plural.</p>
    <h3>Definitions</h3>
    <p class="standard">For the purposes of this Cookies Policy:</p>
    <ul>
        <li>
            <p class="P2" style="margin-left:0cm;"><span class="T2">Company</span>
                (referred to as either "the Company", "We", "Us" or "Our" in this Cookies Policy) refers to SAY
                ORGANIZATION LLC, 1850 S Ocean Dr, Hallandale Beach, 33009. </p>
        </li>
        <li>
            <p class="P2" style="margin-left:0cm;"><span class="T2">Cookies</span> means
                small files that are placed on Your computer, mobile device or any other device by a website, containing
                details of your browsing history on that website among its many uses. </p>
        </li>
        <li>
            <p class="P2" style="margin-left:0cm;"><span class="T2">Website</span> refers
                to Pyjam, accessible from <a href="https://www.pyjam.com" class="ListLabel_20_1"><span
                        class="Internet_20_link">https://www.pyjam.com</span></a> </p>
        </li>
        <li>
            <p class="P2" style="margin-left:0cm;"><span class="T2">You</span> means the
                individual accessing or using the Website, or a company, or any legal entity on behalf of which such
                individual is accessing or using the Website, as applicable. </p>
        </li>
    </ul>
    <h2>The use of the Cookies</h2>
    <h3>Type of Cookies We Use</h3>
    <p class="standard">Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal
        computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web
        browser.</p>
    <p class="standard">We use both session and persistent Cookies for the purposes set out below:</p>
    <p class="simple-line"><span class="T2">Necessary / Essential Cookies</span></p>
    <p class="simple-line">Type: Session Cookies</p>
    <p class="simple-line">Administered by: Us</p>
    <p class="simple-line">Purpose: These Cookies are essential to provide You with services available through the
        Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent
        use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We
        only use these Cookies to provide You with those services.</p>
    <p class="simple-line"><span class="T2">Cookies Policy / Notice Acceptance Cookies</span></p>
    <p class="simple-line">Type: Persistent Cookies</p>
    <p class="simple-line">Administered by: Us</p>
    <p class="simple-line">Purpose: These Cookies identify if users have accepted the use of cookies on the
        Website.</p>
    <p class="simple-line"><span class="T2">Functionality Cookies</span></p>
    <p class="simple-line">Type: Persistent Cookies</p>
    <p class="simple-line">Administered by: Us</p>
    <p class="simple-line">Purpose: These Cookies allow us to remember choices You make when You use the Website,
        such as remembering your login details or language preference. The purpose of these Cookies is to provide You
        with a more personal experience and to avoid You having to re-enter your preferences every time You use the
        Website.</p>
    <p class="simple-line"><span class="T2">Tracking and Performance Cookies</span></p>
    <p class="simple-line">Type: Persistent Cookies</p>
    <p class="simple-line">Administered by: Third-Parties</p>
    <p class="simple-line">Purpose: These Cookies are used to track information about traffic to the Website and
        how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as
        an individual visitor. This is because the information collected is typically linked to a pseudonymous
        identifier associated with the device you use to access the Website. We may also use these Cookies to test new
        pages, features or new functionality of the Website to see how our users react to them.</p>
    <p class="simple-line"><span class="T2">Targeting and Advertising Cookies</span></p>
    <p class="simple-line">Type: Persistent Cookies</p>
    <p class="simple-line">Administered by: Third-Parties</p>
    <p class="simple-line">Purpose: These Cookies track your browsing habits to enable Us to show advertising
        which is more likely to be of interest to You. These Cookies use information about your browsing history to
        group You with other users who have similar interests. Based on that information, and with Our permission, third
        party advertisers can place Cookies to enable them to show adverts which We think will be relevant to your
        interests while You are on third party websites.</p>
    <p class="simple-line"><span class="T2">Social Media Cookies</span></p>
    <p class="simple-line">Type: Persistent Cookies</p>
    <p class="simple-line">Administered by: Third-Parties</p>
    <p class="simple-line">Purpose: In addition to Our own Cookies, We may also use various third party plug-ins
        from social media networking websites such as Facebook, Instagram, Twitter or Google+ to report usage statistics
        of the Website and to provide social media features. These third party plug-ins may store Cookies. We do not
        control these Social Media Cookies. Please refer to the relevant social media networking's website privacy
        policies for information about their cookies.</p>
    <h3>Your Choices Regarding Cookies</h3>
    <p class="standard">If You prefer to avoid the use of Cookies on the Website, first You must disable the use of
        Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may
        use this option for preventing the use of Cookies at any time.</p>
    <p class="standard">If You do not accept Our Cookies, You may experience some inconvenience in your use of the
        Website and some features may not function properly.</p>
    <p class="standard">If You'd like to delete Cookies or instruct your web browser to delete or refuse Cookies, please
        visit the help pages of your web browser.</p>
    <p class="simple-line">For the Chrome web browser, please visit this page from Google: <a
            href="https://support.google.com/accounts/answer/32050" class="ListLabel_20_1"><span
                class="Internet_20_link">https://support.google.com/accounts/answer/32050</span></a></p>
    <p class="simple-line">For the Internet Explorer web browser, please visit this page from Microsoft: <a
            href="http://support.microsoft.com/kb/278835" class="ListLabel_20_1"><span
                class="Internet_20_link">http://support.microsoft.com/kb/278835</span></a></p>
    <p class="simple-line">For the Firefox web browser, please visit this page from Mozilla: <a
            href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
            class="ListLabel_20_1"><span
                class="Internet_20_link">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</span></a>
    </p>
    <p class="simple-line">For the Safari web browser, please visit this page from Apple: <a
            href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
            class="ListLabel_20_1"><span
                class="Internet_20_link">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</span></a>
    </p>
    <p class="standard">For any other web browser, please visit your web browser's official web pages.</p>
    <h3>More Information about Cookies</h3>
    <p class="standard">You can learn more about cookies here: <a href="https://www.termsfeed.com/blog/cookies/"
            class="ListLabel_20_1"><span class="Internet_20_link">All About Cookies by TermsFeed</span></a>.</p>
    <h3>Contact Us</h3>
    <p class="standard">If you have any questions about this Cookies Policy, You can contact us:</p>
    <ul>
        <li>
            <p class="P2" style="margin-left:0cm;">
                By email: <strong>support@pyjam.com</strong>
            </p>
        </li>
    </ul>
</div>
</ion-content>
