import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Client, ReplyResponse, ReplyUpdateRequest, TaskResponse } from '../../api-clients/pyjam/client';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '../../services/loading.service';
import { ToastService } from '../../services/toast.service';
import { NavigationService } from '../../services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { ViewWillEnter } from '@ionic/angular';

@Component({
  selector: 'edit-reply',
  templateUrl: './edit-reply.component.html',
  styleUrls: ['./edit-reply.component.scss'],
})
export class EditReplyComponent implements OnInit, ViewWillEnter {
  public replyId: number;
  public form: FormGroup;
  public reply: ReplyResponse;
  public task: TaskResponse;
  public currentDateTime = new Date();
  public isReplyEdited = false;
  public maxDate: Date;

  constructor(
    private activatedRoute: ActivatedRoute,
    private client: Client,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private navigationService: NavigationService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    this.maxDate = new Date();
    this.maxDate.setDate(new Date().getDate() + 7);

    this.form = new FormGroup({
      price: new FormControl('', [
        Validators.required,
        Validators.min(1),
        Validators.max(999999),
      ]),
      deadline: new FormControl('', [
        Validators.required
      ]),
      description: new FormControl(''),
    });
  }

  ionViewWillEnter() {
    this.loadingService.start().then();

    this.replyId = this.activatedRoute.snapshot.params?.id;
    this.client.replyGet(this.replyId, false).toPromise()
      .then(async (res) => {
        this.reply = res.data;
        this.form.controls.price.setValue(res.data.price);
        this.form.controls.description.setValue(res.data.text);
        this.form.controls.deadline.setValue(new Date(res.data.deadline_at).toISOString());
        this.form.controls.deadline.markAsTouched();
        await this.getTask(this.reply.task_id);
      })
      .catch(async ex => {
        // await this.httpErrorHandlerService.handleHttpException(ex);
      })
      .finally(() => {
        this.form.valueChanges.subscribe(form => {
            this.isReplyEdited =
              form.price !== this.reply.price ||
              new Date(form.deadline).toISOString() !== new Date(this.reply.deadline_at).toISOString() ||
              form.description !== this.reply.text;
          }
        );

        this.loadingService.stop().then();
      });
  }

  private async getTask(id: number): Promise<void> {
    this.client.taskGet(id).toPromise()
      .then((res) => {
        this.task = res.data;
      })
      .catch(async ex => {
        // await this.httpErrorHandlerService.handleHttpException(ex);
      });
  }

  onInputKeyDown($event: KeyboardEvent) {
    if ($event.key == 'ArrowRight' || $event.key == 'ArrowDown'
      || $event.key == 'ArrowLeft' || $event.key == 'ArrowUp'
      || $event.key == 'Delete' || $event.key == 'Backspace'
      || $event.key == '0' || $event.key == '1'
      || $event.key == '2' || $event.key == '3'
      || $event.key == '4' || $event.key == '5'
      || $event.key == '6' || $event.key == '7'
      || $event.key == '8' || $event.key == '9') {
      if (($event.key == '0' || $event.key == '1'
          || $event.key == '2' || $event.key == '3'
          || $event.key == '4' || $event.key == '5'
          || $event.key == '6' || $event.key == '7'
          || $event.key == '8' || $event.key == '9')
        && this.form.controls?.price?.value?.toString()?.length >= 6) {
        $event.preventDefault();
      }
    } else {
      $event.preventDefault();
    }
  }

  async onSaveButtonClick() {
    await this.loadingService.start().then(async () => {
      await this.client.replyPut(this.replyId, {
        price: this.form.controls.price.value,
        text: this.form.controls.description.value,
        deadline_at: this.form.controls.deadline.value,
      } as ReplyUpdateRequest).toPromise()
        .then(async (data) => {
          await this.toastService.success(this.translate.instant('notifications.updateInfo'));
          await this.navigationService.goBack();
        })
        .catch(async ex => {
          // await this.httpErrorHandlerService.handleHttpException(ex);
        })
        .finally(() => {
          this.loadingService.stop().then();
        });
    });
  }
}
