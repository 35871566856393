<div class="content-slot">
  <div class="ion-padding-bottom payout">

    <ion-select
      *ngIf="!pkCode && vm.gateway == 'stripe'"
      placeholder="Choose payment key"
      (ionChange)="selectPaymentMethod($event)">
      <ion-select-option [value]="environment.stripe.key.test">Test</ion-select-option>
      <ion-select-option [value]="environment.stripe.key.live">Live</ion-select-option>
    </ion-select>

    <div class="card-slot" *ngIf="pkCode && vm.gateway == 'stripe' || vm.gateway == 'paypal'">
      <div id="payment-form" class="form">

        <div [ngSwitch]="vm.gateway">
          <div *ngSwitchCase="'stripe'">
            <div id="card-element" class="ion-padding"></div>
          </div>
          <div *ngSwitchCase="'paypal'">
            <div #paypalContainer id="paypal-button-container" class="paypal paypal-container"></div>

            <ion-accordion-group>
              <ion-accordion value="first">
                <ion-item slot="header" color="light">
                  <ion-label class="ion-text-center w-100">
                    {{ 'avatar.orderPay.payWithCard' | translate }}
                  </ion-label>
                </ion-item>

                <div class="ion-padding" slot="content">
                  <label class="paypal-name-label">
                    {{ 'reply.payment.card.name' | translate }}
                  </label>
                  <div id="card-name-field-container" class="paypal paypal-name"></div>
                  <label class="paypal-number-label">
                    {{ 'reply.payment.card.number' | translate }}
                  </label>
                  <div id="card-number-field-container" class="paypal paypal-number"></div>
                  <ion-grid>
                    <ion-row>
                      <ion-col>
                        <label class="paypal-expiry-label">
                          {{ 'reply.payment.card.expiry' | translate }}
                        </label>
                      </ion-col>
                      <ion-col>
                        <label class="paypal-cvv-label">
                          {{ 'reply.payment.card.cvv' | translate }}
                        </label>
                      </ion-col>
                    </ion-row>

                    <ion-row>
                      <ion-col>
                        <div id="card-expiry-field-container" class="paypal paypal-expiry"></div>
                      </ion-col>
                      <ion-col>
                        <div id="card-cvv-field-container" class="paypal paypal-cvv"></div>
                      </ion-col>
                    </ion-row>
                  </ion-grid>

                  <ion-button class="button-bottom"
                              color="dark"
                              expand="block"
                              [appLoaderInButton]="sendCardToken.bind(this)"
                              [disabled]="wasPay || !cardFormIsComplete">
                    {{ 'reply.payment.pay' | translate }}
                  </ion-button>
                </div>
              </ion-accordion>
            </ion-accordion-group>
          </div>
        </div>

        <div class="error">{{cardErrors}}</div>
        <div *ngIf="vm.amount" class="amount">
          <div class="info">{{ 'reply.payment.toPayment' | translate }}</div>
          <div class="value">{{+vm.amount | priceLocale}} {{vm.currency.toUpperCase()}}</div>
        </div>
      </div>
      <div class="footer ion-padding-start ion-padding-end">
        <ion-button *ngIf="vm.gateway !== 'paypal'"
                    [appLoaderInButton]="sendCardToken.bind(this)"
                    class="button-bottom"
                    type="default"
                    color="dark"
                    expand="block"
                    [disabled]="wasPay || !cardFormIsComplete">
          {{ 'reply.payment.pay' | translate }}
        </ion-button>
      </div>
    </div>
  </div>
</div>
