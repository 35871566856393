import { Injectable, NgZone } from '@angular/core';
import { Config } from '@ionic/angular';
import { NavigationService } from './navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivatedRouteFixService } from './activated-route-fix.service';

@Injectable({
  providedIn: 'root'
})
export class BackButtonService {
  count: number;

  constructor(
    private config: Config,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private router: Router,
    private ngZone: NgZone,
  ) {
    this.registerBackButtonAction();
  }

  registerBackButtonAction() {
    if (typeof document !== 'undefined') {
      document.addEventListener('ionBackButton', (event: Event): void => {
        this.ngZone.run((): void => {
          this.goBack(null);
        });
      }, false);
    }
  }

  public goBack(defHref: any, queryParams = null): void {
    const defaultHref = defHref || this.config.get('backButtonDefaultHref');
    const prevUrl = this.router.url;
    this.navigationService.goBack(
      true,
      defaultHref,
      defaultHref?.length > 0 && defaultHref[0] == '/'
        ? null
        : this.activatedRouteFix.getActivatedRoute(this.activatedRoute),
      queryParams
    ).then(() => {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.removeItem('showMenu');
        if (prevUrl.indexOf('.html') !== -1) {
          sessionStorage.setItem('noAuthBtn', '1');
        } else {
          sessionStorage.removeItem('noAuthBtn');
        }
      }
      setTimeout((): void => {
        if (this.router.url == prevUrl) { // navigation cannot go back further
          this.router.navigateByUrl('/avatar').then();
        }
      }, 100);
    });
  }
}
