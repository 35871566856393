<div class="phone-with-code">
  <div
    class="country-input"
    id="open-modal">
    <div class="flag">{{this.phone?.code?.img}}</div>
    <div class="phone-code-output">{{this.phone?.code?.phone_code}}</div>
    <ion-icon src="/assets/icon/dropdown-arrow.svg" class="dropdown-arrow"></ion-icon>
  </div>

  <ion-modal #modal trigger="open-modal">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-title>{{'sign.countryCodeTitle' | translate}}</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="modal.dismiss()">
              <img src="../../../../assets/icon/close.svg" alt="close">
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list>
          <ion-radio-group allow-empty-selection="false" name="radio-group" [value]="phone.code" (ionChange)="phoneCodeChanged($event)">
            <ion-item *ngFor="let code of codes">
              <div style="margin-right: 15px">{{code?.img}}</div>
              <ion-label>
                <h2>{{code.name}}</h2>
                <p>{{code.phone_code}}</p>
              </ion-label>
              <ion-radio slot="end" [value]="code"></ion-radio>
            </ion-item>
          </ion-radio-group>

        </ion-list>
      </ion-content>
    </ng-template>
  </ion-modal>

  <div class="phone-input">
    <ion-input
      class="custom-ion-input"
      [placeholder]="'sign.enterPhonePlaceholder' | translate"
      (keydown)="onInputKeyDown($event)"
      (paste)="onInputPaste($event)"
      type="tel"
      (ionChange)="onInputValueChange($event)"
      (ionBlur)="onInputFocus()"
      autofocus="true">
    </ion-input>
  </div>
</div>
