import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ViewDidEnter } from '@ionic/angular';
import { StatusBar } from '@capacitor/status-bar';
import { PlatformService } from '../services/platform.service';

@Component({
  selector: 'pyjam-animate',
  templateUrl: './pyjam-animate.component.html',
  styleUrls: ['./pyjam-animate.component.scss'],
})
export class PyjamAnimateComponent implements ViewDidEnter {
  private delayedRedirect: number = 2_500; // 2,5 s

  constructor(
    private router: Router,
    private platformService: PlatformService,
  ) {
    this.statusBarOverlayActivate().then();
  }

  ionViewDidEnter() {
    setTimeout(async (): Promise<void> => {
      if (!localStorage.getItem('introPassed')) {
        await this.router.navigate(['/tutorial-control'], {replaceUrl: true});
      } else if (this.router.url === '/pyjam-animate') {
        await this.router.navigate(['/avatar']);
      }
      await this.statusBarOverlayDeactivate();
    }, this.delayedRedirect);
  }

  async statusBarOverlayActivate(): Promise<void> {
    if (this.platformService.isDevice && this.platformService.isAndroidApp) {
      try {
        await StatusBar.setOverlaysWebView({overlay: true});
      } catch (error) {
        console.error('Error setting status bar overlay:', error);
      }
    }
  }

  async statusBarOverlayDeactivate(): Promise<void> {
    if (this.platformService.isDevice && this.platformService.isAndroidApp) {
      try {
        await StatusBar.setOverlaysWebView({overlay: false});
      } catch (error) {
        console.error('Error setting status bar overlay:', error);
      }
    }
  }
}
