import { ModalController, ViewWillEnter } from '@ionic/angular';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { SubscriptionsBag } from './subscriptions-bag';

export class ModalComponentBase implements ViewWillEnter {
  private static modalLevel: number = 0;
  private dataToDismiss = null;
  private dismissed: boolean = false;
  private sb: SubscriptionsBag = new SubscriptionsBag();
  private myModalLevel: number;

  constructor(
    protected modalController: ModalController,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected location: Location,
  ) {
  }

  ionViewWillEnter(): void {
    this.init().then();
  }

  // ngOnDestroy(): void {
  //   this.sb.unsubscribeAll();
  // }

  public async init(): Promise<void> {
    if (this.myModalLevel > 0) return;

    this.myModalLevel = ++ModalComponentBase.modalLevel;

    return this.router.navigate([], {
      queryParams: {modalLevel: this.myModalLevel},
      queryParamsHandling: 'merge'
    }).then((): void => {
      this.sb.sub = this.activatedRoute.queryParamMap.pipe(
        map((qp: ParamMap) => parseInt(qp.get('modalLevel')) || 0),
        distinctUntilChanged()
      ).subscribe((modalLevel: number): void => {
        ModalComponentBase.modalLevel = modalLevel;

        if ((!modalLevel || modalLevel < this.myModalLevel) && !this.dismissed) {
          this.dismissed = true;
          this.modalController.dismiss(this.dataToDismiss).then();
          this.sb.unsubscribeAll();
        }
      });
    });
  }

  public async dismiss(data: any = undefined): Promise<void> {
    this.dataToDismiss = data;

    if (this.location && (typeof window !== 'undefined') && window.location.pathname !== '/login/sign-in') {
      this.location.back();
    } else {
      await this.router.navigate([], {
        queryParams: {modalLevel: this.myModalLevel - 1 || undefined},
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }
}
