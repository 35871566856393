import { Component, EventEmitter, Input, Output, ViewChild, } from '@angular/core';
import { FileTypes, } from '../../api-clients/pyjam/extended-client';
import { ToastService } from '../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { FilePicker, PickFilesOptions, PickFilesResult, } from '@capawesome/capacitor-file-picker';
import { LoadingService } from '../../services/loading.service';
import { FileUploadingService } from '../../services/file-uploading.service';
import { ActionSheetController, ActionSheetOptions } from '@ionic/angular';
import { FileResponse } from '../../api-clients/pyjam/client';
import { AuthService } from '../../auth/auth.service';
import { ImageSliderComponent } from '../image-slider/image-slider.component';
import { PlatformService } from '../../services/platform.service';
import { SIZE_LIMIT } from '../../app.constants';

@Component({
  selector: 'app-image-adding',
  templateUrl: './image-adding.component.html',
  styleUrls: ['./image-adding.component.scss'],
})
export class ImageAddingComponent {
  @Input() set files(addingFiles: AddingFile[]) {
    this.addingFiles = addingFiles;
  }

  @Input() acceptAdding: boolean = true;
  @Input() acceptEditing: boolean = true;
  @Input() withClosing: boolean = true;
  @Output() public filesUpdated: EventEmitter<AddingFile[]> = new EventEmitter<AddingFile[]>();
  @Output() public fileDeleted: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('imageSlider') imageSlider: ImageSliderComponent;
  public addingFiles: AddingFile[] = [];

  get dataFiles(): FileResponse[] {
    return this.addingFiles.map((file: AddingFile) => {
      return {
        id: file.id,
        name: file.name,
        type: {id: this.typeIdByName(file.mimeType), name: file.mimeType},
        path: file.src,
        size: file.size,
        url: file.src
      } as FileResponse;
    });
  }

  constructor(
    private translate: TranslateService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private fileUploadingService: FileUploadingService,
    private actionSheetController: ActionSheetController,
    private platformService: PlatformService,
    private authService: AuthService
  ) {
  }

  public async delete(i: number): Promise<void> {
    const deletedFile: AddingFile = this.addingFiles.splice(i, 1)[0];

    if (!!deletedFile.id) {
      this.fileDeleted.emit(deletedFile.id);
    } else {
      this.filesUpdated.emit(this.addingFiles);
    }
  }

  public async onAddFileButtonClick(): Promise<void> {
    if (!this.authService.isAuthenticated) {
      await this.toastService.warning(this.translate.instant('common.loginToUploadFiles'));
      return;
    }

    try {
      let result: PickFilesResult = {files: []};

      if (this.platformService.isDevice && this.platformService.isIos) {
        const opts: ActionSheetOptions = {
          buttons: [
            {
              text: this.translate.instant('userProfile.buttons.gallery'),
              icon: 'camera',
              handler: async (): Promise<void> => {
                try {
                  const mediaOptions = {
                    readData: true,
                    skipTranscoding: false
                  };
                  const picked: PickFilesResult = await FilePicker.pickImages(mediaOptions);

                  if (picked.files.length > 0) {
                    for (let file of picked.files) {
                      if (file.size > SIZE_LIMIT.IMAGE) {
                        await this.toastService.warning(file.name, this.translate.instant('errors.maxSizeImage'));
                        return;
                      }
                    }

                    // let file: PickedFile = picked.files[0];
                    // const fileType: string = file.mimeType;
                    // const isHeic: boolean = fileType === 'image/heic' || file.path.endsWith('.heic');
                    //
                    // if (isHeic) {
                    //   const options: ConvertHeicToJpegOptions = {path: file.path};
                    //   const jpegFile: ConvertHeicToJpegResult = await FilePicker.convertHeicToJpeg(options);
                    //   file.path = jpegFile.path;
                    //   result.files = [file];
                    // } else {
                    result = picked;
                    // }
                  }
                } catch (error) {
                  if (error.message != 'pickFiles canceled.') {
                    console.error('pickFiles error', error);
                  }
                }
              }
            },
            {
              text: this.translate.instant('userProfile.buttons.files'),
              icon: 'document',
              handler: async (): Promise<void> => {
                try {
                  const filesOptions = {
                    readData: true,
                    types: ['image/jpeg', 'image/png', 'image/webp']
                  };

                  const picked: PickFilesResult = await FilePicker.pickFiles(filesOptions);

                  if (picked.files.length > 0) {

                    for (let file of picked.files) {
                      if (file.size > SIZE_LIMIT.IMAGE) {
                        await this.toastService.warning(file.name, this.translate.instant('errors.maxSizeImage'));
                        return;
                      }
                    }

                    result = picked;
                  }
                } catch (error) {
                  if (error.message != 'pickFiles canceled.') {
                    console.error('pickFiles error', error);
                  }
                }
              }
            }
          ]
        };
        const actionSheet: HTMLIonActionSheetElement = await this.actionSheetController.create(opts);
        await actionSheet?.present();
        await actionSheet.onDidDismiss();
      }

      if (!this.platformService.isDevice || (this.platformService.isDevice && !this.platformService.isIos)) {
        const filesOptions: PickFilesOptions = {
          readData: true,
          types: ['image/jpeg', 'image/png', 'image/webp']
        };

        const picked: PickFilesResult = await FilePicker.pickFiles(filesOptions);

        if (picked.files.length > 0) {

          if (picked.files[0].size > SIZE_LIMIT.IMAGE) {
            await this.toastService.warning(this.translate.instant('errors.maxSizeImage'));
            return;
          }

          result = picked;
        }
      }

      if (result.files.length > 0) {
        try {
          await this.loadingService.start();

          this.addingFiles.push(...await this.fileUploadingService.pickedFilesToAddingFiles(result.files));
          this.filesUpdated.emit(this.addingFiles);
        } catch (error) {
          console.error(error);
        } finally {
          await this.loadingService.stop();
        }
      }

    } catch (error) {
      console.log(error, 'error!');
      if (error.message != 'pickFiles canceled.') {
        console.error('onAddFileButtonClick error', error);
      }
    }
  }

  public typeIdByName(type: string): number {
    switch (type) {
      case 'image':
        return FileTypes.TYPE_IMAGE;
      case 'video':
        return FileTypes.TYPE_VIDEO;
      case 'audio':
        return FileTypes.TYPE_AUDIO;
      case 'pdf':
        return FileTypes.TYPE_PDF;
      case 'avatar':
        return FileTypes.TYPE_AVATAR;
      default:
        return FileTypes.TYPE_OTHER;
    }
  }
}


export class AddingFile {
  id?: number;
  mimeType: string;
  base64: string;
  src: string;
  name: string;
  size: number;
  blob?: Blob;
}
