<ion-header class="black">
  <ion-toolbar>
    <ion-title>{{ 'notifications.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="authService.isAuthenticated; else login_btn" class="notifications-slot ion-padding-bottom ion-padding-start ion-padding-end"
       infinite-scroll
       id="infiniteScroll"
       [horizontal]="false"
       [scrollWindow]="false"
       [infiniteScrollThrottle]="0"
       (scrolled)="onScrollDown()">
    <div class="notification-card ion-justify-content-between"
         *ngFor="let notification of notifications; let i = index" (click)="check(notification)">
      <div class="icon-slot"
           *ngIf="notificationIcons[notification.type];else noIcon"
           [ngClass]="{
            'type-1-green': notificationIcons[notification.type] === 'add-outline',
            'type-2-purple': notificationIcons[notification.type] === 'information-circle-outline',
            'type-3-red': notificationIcons[notification.type] === 'alert-outline'
          }">
        <div class="icon">
          <ion-icon [name]="notificationIcons[notification.type]" color="light"></ion-icon>
        </div>
      </div>

      <ng-template #noIcon>
        <div *ngIf="notification.type === NOTIFICATION_TYPE.AVATAR_ORDER
                 || notification.type === NOTIFICATION_TYPE.AVATAR_ORDER_CANCELLED
                 || notification.type === NOTIFICATION_TYPE.AVATAR_ORDER_ACCEPTED
                 || notification.type === NOTIFICATION_TYPE.AVATAR_ORDER_REJECTED
                 || notification.type === NOTIFICATION_TYPE.AVATAR_ORDER_DONE
                 ; else noAvatarOrder">
          <div class="icon-slot">
            <div class="icon">
              <div class="avatar" *ngIf="notification.body?.userFrom?.avatar?.origin?.url; else initials"
                   [ngStyle]="{background: 'no-repeat center / cover url(' + notification.body?.userFrom?.avatar?.origin?.url + ')'}">
              </div>
              <ng-template #initials>
                <div class="initials">
                  {{ notification.body?.userFrom?.name + ' ' + notification.body?.userFrom?.surname | initials }}
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <ng-template #noAvatarOrder>
          <div class="icon-slot">
            <div class="icon">
              <ion-icon name="analytics-outline" color="light"></ion-icon>
            </div>
          </div>
        </ng-template>
      </ng-template>

      <div class="info-slot">
        <div class="info" style="width: 100%">
          <div [ngSwitch]="notification.type">
            <div *ngSwitchCase="NOTIFICATION_TYPE.MESSAGE">
              {{ 'notifications.newMessage' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.COMMENT">
              {{ 'notifications.taskNewComment' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.REPLY">
              {{ 'notifications.newResponse' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.OFFER">
              {{ 'notifications.newOrder' | translate: {value: notification.body?.taskName} }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.STATUS">
              <span *ngIf="notification.body?.replyStatus == 15">
                {{ 'notifications.disputedResolve' | translate : {order: notification.body?.taskName} }}
              </span>
              <span *ngIf="notification.body?.replyStatus != 15">
                {{ 'notifications.newOrderStatus' | translate: {
                  order: notification.body?.taskName,
                  status: 'notifications.orderStatus.' + notification.body?.replyStatus | translate
              } }}
              </span>
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.EXTRA_WORK">
              {{ 'notifications.fixRequest' | translate: {order: notification.body?.taskName} }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.TASK">
              {{ 'notifications.newTask' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.CHAT">
              {{ 'notifications.newChatWasCreated' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.PAYMENT_SUCCEEDED">
              {{ 'notifications.paymentSucceeded' | translate: {task: notification.body?.taskName} }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.ONBOARDING">
              {{ 'notifications.onboardingUpdated' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.INACTIVITY_EXTRA_WORK">
              {{ 'notifications.waitingForDecisionOrUncompleted' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.INACTIVITY_DEADLINE">
              {{ 'notifications.warningIfDoNothing' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.INACTIVITY_OWNER">
              {{ 'notifications.workIsDoneWillTakeMoneyIn3Days' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.DISPUTED">
              {{ 'notifications.disputeOpened' | translate: {
                value: notification.body?.isOwner
                    ? ('notifications.customer' | translate)
                    : ('notifications.executor' | translate),
                task: notification.body?.taskName
            } }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.OFFER_STATUS_ACCEPTED">
              {{ 'notifications.executorIsReady' | translate: {task: notification.body?.taskName} }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.OFFER_STATUS_REJECTED">
              {{ 'notifications.executorRejectedOrder' | translate: {task: notification.body?.taskName} }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.DEADLINE">
              {{ 'notifications.timeIsEndingForOrder' | translate: {task: notification.body?.taskName} }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.EXTRA_WORK_REJECTED">
              {{ 'notifications.executorRejectedFixes' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.EXTRA_WORK_OFFER_EXTRA_PAY">
              {{ 'notifications.executorOfferedPayment' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.EXTRA_WORK_OWNER_APPROVE">
              {{ 'notifications.customerAgreed' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.EXTRA_WORK_PAID">
              {{ 'notifications.customerPayedFixed' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.EXTRA_WORK_WORKER_APPROVE">
              {{ 'notifications.executorAgreed' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.EXTRA_WORK_PENDING">
              {{ 'notifications.extraWorkPending' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.EXTRA_WORK_WORKER_ADDED_RESULTS">
              {{ 'notifications.extraWorkWorkerAddResults' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.REPLY_DATA_UPDATED">
              {{ 'notifications.replyDataUpdated' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.EXTRA_WORK_WAIT_PAY">
              {{ 'notifications.extraWorkWaitPay' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.DISPUTE_CANCELED">
              {{ 'notifications.disputeCanceled' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.EXTRA_WORK_DELETED">
              {{ 'notifications.extraWorkDeleted' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.EXTRA_WORK_OWNER_APPROVE_RESULTS">
              {{ 'notifications.extraWorkOwnerApproveResults' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.OFFER_CREATE_TASK">
              {{ 'notifications.offerCreateTask' | translate: {
                task: notification.body?.taskName,
                category: notification.body?.categoryName
            } }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.PAYMENT_FAILED">
              {{ 'notifications.paymentFailed' | translate: {task: notification.body?.taskName} }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.PAYMENT_CANCELED">
              {{ 'notifications.paymentCanceled' | translate: {task: notification.body?.taskName} }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.PAYMENT_AFTER_5_DAYS">
              {{ 'notifications.paymentAfter5Days' | translate: {task: notification.body?.taskName} }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.DISPUTED_TASK_FOR_TRUSTED_USER">
              {{ 'notifications.disputedTaskForTrustedUser' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.PAY_FOR_TRUSTED_USER">
              {{ 'notifications.payForTrustedUser' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.PAYMENT_SUCCEEDED_REPLY_TIPS">
              {{ 'notifications.tips' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.PAYMENT_SUCCEEDED_AVATAR_ORDER_TIPS">
              {{ 'notifications.tips' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.AVATAR_ORDER">
              {{ 'notifications.avatarOrder' | translate: {
                name: notification.body?.userFrom?.name || ('deletedUser' | translate),
                surname: notification.body?.userFrom?.surname || ''
              } }}
              <br>
              <span *ngIf="notification.body?.price">
                {{ 'notifications.avatarOrderPrice' | translate: {
                  price: notification.body.price.toFixed(2),
                  currency: notification.body?.currency || ('avatar.currency' | translate)
              } }}
              </span>
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.AVATAR_ORDER_CANCELLED">
              {{ 'notifications.avatarOrderCancelled' | translate: {
                name: notification.body?.userFrom?.name || ('deletedUser' | translate),
                surname: notification.body?.userFrom?.surname || ''
              } }}
              <br>
              <span *ngIf="notification.body?.price">
                {{ 'notifications.avatarOrderPrice' | translate: {
                  price: notification.body.price.toFixed(2),
                  currency: notification.body?.currency || ('avatar.currency' | translate)
              } }}
              </span>
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.AVATAR_ORDER_ACCEPTED">
              {{ 'notifications.avatarOrderAccepted' | translate: {
                name: notification.body?.userFrom?.name || ('deletedUser' | translate),
                surname: notification.body?.userFrom?.surname || ''
              } }}
              <br>
              <span *ngIf="notification.body?.price">
                {{ 'notifications.avatarOrderPrice' | translate: {
                  price: notification.body.price.toFixed(2),
                  currency: notification.body?.currency || ('avatar.currency' | translate)
                } }}
              </span>
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.AVATAR_ORDER_REJECTED">
              {{ 'notifications.avatarOrderRejected' | translate: {
                name: notification.body?.userFrom?.name || ('deletedUser' | translate),
                surname: notification.body?.userFrom?.surname || ''
              } }}
              <br>
              <span *ngIf="notification.body?.price">
                {{ 'notifications.avatarOrderPrice' | translate: {
                  price: notification.body.price.toFixed(2),
                  currency: notification.body?.currency || ('avatar.currency' | translate)
                } }}
              </span>
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.AVATAR_ORDER_DONE">
              {{ 'notifications.avatarOrderDone' | translate: {
                name: notification.body?.userFrom?.name || ('deletedUser' | translate),
                surname: notification.body?.userFrom?.surname || ''
              } }}
              <br>
              <span *ngIf="notification.body?.price">
                {{ 'notifications.avatarOrderPrice' | translate: {
                  price: notification.body.price.toFixed(2),
                  currency: notification.body?.currency || ('avatar.currency' | translate)
                } }}
              </span>
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.ACCOUNT_PAYPAL_INVALID">
              {{ 'notifications.accountPayPalInvalid' | translate }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.TASK_UNPUBLISHED">
              {{ 'notifications.taskUnpublished' | translate: {
                name: notification.body.taskName
              } }}
            </div>
            <div *ngSwitchCase="NOTIFICATION_TYPE.TASK_WILL_BE_UNPUBLISHED_AFTER_5_DAYS">
              {{ 'notifications.taskWillBeUnpublishedAfter5days' | translate }}
            </div>
            <div *ngSwitchDefault>
              {{ 'notifications.notification' | translate }}
            </div>
          </div>
        </div>

        <div class="date">
          {{ notification.created_at | date: 'dd.MM.YYYY HH:mm' }}
          <ng-container *ngIf="notification.body?.duration">
            | {{ 'notifications.duration' | translate: {duration: notification.body.duration} }}
          </ng-container>
        </div>
      </div>

      <div class="delete" (click)="delete($event, notification.id, i)">
        <ion-icon slot="icon-only" color="light" name="close"></ion-icon>
      </div>

      <div *ngIf="notification.type === NOTIFICATION_TYPE.AVATAR_ORDER || notification.type === NOTIFICATION_TYPE.AVATAR_ORDER_CANCELLED"
           class="chat-icon-container">
        <ion-icon (click)="goToChat(notification.body?.userFrom.id)"
                  class="chat-icon"
                  src="/assets/icon/chat.svg">
        </ion-icon>
      </div>

    </div>
    <div *ngIf="notifications.length === 0" class="empty">
      {{ 'notifications.empty' | translate }}
    </div>
  </div>
  <ng-template #login_btn>
    <ion-button class="login-btn" shape="round" color="dark" expand="block" (click)="logInClick()">
      {{ 'registration.logIn' | translate }}
    </ion-button>
  </ng-template>
</ion-content>

