<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon class="menu" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ 'report.reportByType.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-bottom ion-padding-start ion-padding-end">
  <form *ngIf="form" [formGroup]="form" class="custom-form">
    <h2 class="title">{{ 'report.reason.title' | translate }}</h2>
    <div class="field">
      <ion-select interface="action-sheet"
                  formControlName="reportReason"
                  class="custom-ion-textarea"
                  name="textarea"
                  placeholder="{{ 'report.reason.placeholder' | translate }}"
                  maxlength="1500">
        <ion-select-option
          *ngFor="let reason of reportReasons" [value]="reason">{{ ('report.reason.' + reason) | translate}}</ion-select-option>
      </ion-select>
      <div *ngIf="form.touched && form.controls.reportReason.errors?.required" class="error">
        {{ 'report.reportByType.requiredField' | translate }}
      </div>
    </div>
    <h2 class="title">{{ 'report.reportByType.description' | translate }}</h2>
    <div class="field">
      <ion-textarea formControlName="description"
                    class="custom-ion-textarea"
                    name="textarea"
                    placeholder="{{ 'report.reportByType.description' | translate }}"
                    rows="6"
                    maxlength="1500">
      </ion-textarea>
      <div *ngIf="form.touched && form.controls.description.errors?.required" class="error">
        {{ 'report.reportByType.requiredField' | translate }}
      </div>
    </div>
  </form>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-button class="ion-margin button-bottom"
              color="dark"
              expand="block"
              [disabled]="form?.invalid || (form?.valid && !form.controls.description.value)"
              (click)="onSendUserReportClick()">
    {{ 'report.reportByType.continue' | translate }}
  </ion-button>
</ion-footer>
