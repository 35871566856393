import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ITaskFileRequest } from '../../api-clients/pyjam/extended-client';
import { Client, DisputeDescriptionRequest, FileParameter } from '../../api-clients/pyjam/client';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRouteFixService } from '../../services/activated-route-fix.service';
import { LoadingService } from '../../services/loading.service';
import { ViewDidEnter } from '@ionic/angular';

@Component({
  selector: 'dispute-description',
  templateUrl: './dispute-description.component.html',
  styleUrls: ['./dispute-description.component.scss'],
})
export class DisputeDescriptionComponent implements ViewDidEnter {
  private replyId: number;
  public form: FormGroup;
  public files = [] as ITaskFileRequest [];
  // public pushedFiles: ITaskFileRequest [] = [];
  // public filesForSent: FileParameter [] = [];
  public isCheckedLicenseAgreement = false;
  public isAcceptLicenseAgreement = false;

  constructor(
    private client: Client,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFixService: ActivatedRouteFixService,
    private router: Router,
    private toastService: ToastService,
    private translate: TranslateService,
    private loadingService: LoadingService,
  ) {
  }

  ionViewDidEnter() {
    this.replyId = this.activatedRoute.snapshot.params?.id;
    this.form = new FormGroup({
      description: new FormControl('', [
        Validators.required
      ]),
    });
  }

  public async onReplyDisputeClick() {
    this.loadingService.start().then(() => {
      this.client.replyDispute(this.replyId, {dispute_description: this.form.controls.description.value} as DisputeDescriptionRequest)
        .toPromise()
        .then(async () => {
          await this.router.navigate(['..'], {
            relativeTo: this.activatedRouteFixService.getActivatedRoute(this.activatedRoute),
            replaceUrl: true,
          });
        })
        .catch(async ex => {
          if (ex.result?.message) {
            await this.toastService.error(this.translate.instant('errors.' + ex.result.message));
          }
          // await this.httpErrorHandlerService.handleHttpException(ex);
        })
        .finally(() => this.loadingService.stop().then());
    });
  }

  public async onAcceptAgreementClick() {
    this.isAcceptLicenseAgreement = true;
  }

  agreementCheckboxChange($event: any) {
    this.isCheckedLicenseAgreement = $event.detail.checked;
  }
}
