import { Component } from '@angular/core';
import { TaskResultController } from '../task-result.controller';
import { NavigationService } from '../../../services/navigation.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { TaskResultDataResponse } from '../../../api-clients/pyjam/client';
import { AddingFile } from '../../../ui-components/image-adding/image-adding.component';
import { FileUploadingService } from '../../../services/file-uploading.service';
import { ViewWillEnter } from '@ionic/angular';

@Component({
  selector: 'edit-task-result',
  templateUrl: './edit-task-result.component.html',
  styleUrls: ['./edit-task-result.component.scss'],
})
export class EditTaskResultComponent implements ViewWillEnter {
  private replyId: number;
  public form: FormGroup;
  public files = [] as AddingFile[];

  constructor(
    private activatedRoute: ActivatedRoute,
    public controller: TaskResultController,
    private navigationService: NavigationService,
    private loadingService: LoadingService,
    private fileUploadingService: FileUploadingService,
  ) {
  }

  ionViewWillEnter() {
    this.replyId = this.activatedRoute.snapshot.params?.id;
    this.controller.initialisationPromise.then((): void => {
      this.form = new FormGroup({
        description: new FormControl('', [Validators.required]),
      });
    });
  }

  public removeData(): void {
    console.error('removeData not implemented');
  }

  public async close(): Promise<void> {
    await this.navigationService.goBack();
  }

  public async onFilesUpdated($event: AddingFile[]): Promise<void> {
    this.files = $event;
  }

  public async onOKClick(): Promise<void> {
    if (!this.form.valid) {
      return;
    }
    try {
      await this.loadingService.start();
      // this.controller.pushFiles(this.files);
      this.controller.pushDescription(this.form.controls.description.value);
      const res: TaskResultDataResponse = await this.controller.editTaskResult();
      for (const file of this.files) {
        await this.fileUploadingService.taskResultAddFileToS3(res.data.id, file);
      }
      await this.navigationService.goBack();
    } catch (error) {
      console.error(error);
    } finally {
      await this.loadingService.stop();
    }
  }
}
