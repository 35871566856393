import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ActionSheetController, ActionSheetOptions, ModalController, ModalOptions } from '@ionic/angular';
import { ExtendedClient, FileTypes } from '../../api-clients/pyjam/extended-client';
import { FileResponse } from '../../api-clients/pyjam/client';
import { Browser } from '@capacitor/browser';
import Swiper, { Pagination, Zoom } from 'swiper';
import { TranslateService } from '@ngx-translate/core';
import {
  AddDescriptionToImageComponent,
  AddingFileWithDescription
} from '../add-description-to-image/add-description-to-image.component';
import { ToastService } from '../../services/toast.service';
import { HttpErrorHandlerService } from '../../services/error-handlers/http-error-handler.service';
import { LoadingService } from '../../services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, fromEvent } from 'rxjs';
import { BackButtonService } from '../../services/back-button.service';
import { ReportService } from '../../services/report.service';
import { ReportType } from '../../report/report.constants';

Swiper.use([Pagination, Zoom]);

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit {
  public readonly FileTypes = FileTypes;
  @ViewChild('swiperContainer', {static: false}) swiperContainer: ElementRef;
  swiper: Swiper;
  profile = null;

  @Input()
  public set selectedFilesForSlider(value: any[]) {
    let selectedFilesLengthBefore = this.selectedFiles.length;
    this.selectedFiles = value;

    if (this.selectedFiles.length > 0) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          showImage: true,
        },
        queryParamsHandling: 'merge',
      }).then();
    } else {
      if (selectedFilesLengthBefore > 0 && (typeof window !== 'undefined') && window.location.search.includes('showImage')) {
        this.backButtonService.goBack(null);
      }
    }
  };

  public get selectedFilesForSlider(): any[] {
    return this.selectedFiles;
  };

  public closeModal() {
    this.selectedFilesForSlider = [];
  }

  @Input()
  public set acceptEditing(isAcceptEditing) {
    if (isAcceptEditing === false) {
      this.menuButtons.push(
        {
          text: this.translate.instant('report.type.' + ReportType.FILE),
          handler: () => {
            this.reportService.openReportModal(ReportType.FILE, this.selectedFilesForSlider[this.swiper.activeIndex].id).then();
          }
        }
      );
    } else {
      this.menuButtons.push(
        {
          text: this.translate.instant('userProfile.buttons.edit'),
          handler: async () => {
            const filesForSent: AddingFileWithDescription[] = [];
            const newFile = JSON.parse(JSON.stringify(this.selectedFilesForSlider[this.swiper.activeIndex]));
            newFile.type = newFile.type.name;
            filesForSent.push({
              id: newFile.id,
              mimeType: newFile.type,
              base64: '',
              src: newFile.resizes?.length > 0 ? newFile.resizes[0].url : newFile.url,
              size: newFile.size,
              name: newFile.name,
              description: newFile.description
            } as AddingFileWithDescription);

            const opts: ModalOptions = {
              component: AddDescriptionToImageComponent,
              componentProps: {
                files: filesForSent,
              },
              backdropDismiss: false,
              keyboardClose: false,
            };
            const modal: HTMLIonModalElement = await this.modalController.create(opts);
            await modal?.present();

            const eventDetail = await modal.onDidDismiss();

            if (eventDetail && eventDetail.data && eventDetail.data.files && eventDetail.data.files.length) {
              try {
                await this.loadingService.start();

                await firstValueFrom(this.extendedClient.profileFileDescriptionChange(eventDetail.data.files[0]));
                await this.toastService.success(this.translate.instant('userProfile.fileChanged'));
                this.selectedFilesForSlider[this.swiper.activeIndex].description = eventDetail.data.files[0].description;
              } catch (error) {
                await this.httpErrorHandlerService.handleHttpException(error);
              } finally {
                await this.loadingService.stop();
              }
            }
          }
        },
        {
          role: 'destructive',
          text: this.translate.instant('userProfile.buttons.remove'),
          handler: () => {
            this.deleteFile.emit(this.selectedFilesForSlider[this.swiper.activeIndex].id);
          }
        },
      );
    }
  }


  @Output()
  public deleteFile = new EventEmitter();

  private selectedFiles: any[] = [];
  private menuButtons = [];
  public isAcceptEditing = true;

  constructor(
    private actionSheetController: ActionSheetController,
    private translate: TranslateService,
    private toastService: ToastService,
    private extendedClient: ExtendedClient,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private modalController: ModalController,
    private loadingService: LoadingService,
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private backButtonService: BackButtonService,
    private reportService: ReportService,
  ) {
  }

  ngOnInit() {
    if (typeof window !== 'undefined') {
      fromEvent(window, 'popstate').subscribe(() => {
        if (this.selectedFilesForSlider.length > 0) {
          this.selectedFilesForSlider = [];
        }
      });
    }
  }

  public async showImages(files: FileResponse[], index: number, profile?) {
    let initialSlide = index | 0;
    let filesForSlider = [];
    for (let i = initialSlide; i < files.length; i++) {
      filesForSlider.push(files[i]);
    }
    if (initialSlide > 0) {
      for (let i = 0; i < initialSlide; i++) {
        filesForSlider.push(files[i]);
      }
    }
    this.selectedFilesForSlider = filesForSlider;

    setTimeout(() => {
      this.swiper = new Swiper(
        '.image-wiper',
        {
          loop: false,
          zoom: {
            maxRatio: 3
          },
          initialSlide: 0,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
          },
          onAny(eventName, ...args) {
            if ((typeof document !== 'undefined') && eventName.toString() == 'slideChangeTransitionStart') {
              let elementsVideo = document.getElementsByTagName('video');
              for (let i = 0; i < elementsVideo.length; i++) {
                if (elementsVideo[i].classList.contains('video-image-set')) {
                  elementsVideo[i].pause();
                }
              }

              let elementsAudio = document.getElementsByTagName('audio');
              for (let i = 0; i < elementsAudio.length; i++) {
                if (elementsAudio[i].classList.contains('audio-image-set')) {
                  elementsAudio[i].pause();
                }
              }
            }
          }
        }
      );
    });

    if (profile) {
      this.profile = profile;
    }
  }

  public async downloadFile() {
    let file: FileResponse = this.selectedFilesForSlider[this.swiper.activeIndex];
    await Browser.open({url: file?.origin?.url ? file?.origin?.url : file.url});
  }

  async openMenu() {
    await this.ngZone.runOutsideAngular(async () => {
      const opts: ActionSheetOptions = {
        buttons: this.menuButtons,
      };
      const actionSheet: HTMLIonActionSheetElement = await this.actionSheetController.create(opts);
      await actionSheet?.present();
      await actionSheet.onDidDismiss();
    });
  }

  reportFile() {
    this.reportService.openReportModal(ReportType.FILE, this.selectedFilesForSlider[this.swiper.activeIndex].id).then();
  }
}
