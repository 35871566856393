<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons
      slot="start">
      <app-back-button (click)="removeData()"></app-back-button>
    </ion-buttons>
    <ion-title>
      {{ 'reply.addFiles.editTaskResult' | translate }}
    </ion-title>
    <ion-buttons
      slot="end">
      <ion-button
        (click)="close()">
        <ion-icon
          slot="icon-only"
          name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-start ion-padding-end ion-margin-bottom">
  <form *ngIf="form" [formGroup]="form" class="custom-form">
    <div class="field">
      <ion-textarea [placeholder]="'reply.editReply.commentPlaceholder' | translate"
                    style="margin-top: 50px"
                    formControlName="description"
                    class="custom-ion-textarea"
                    name="textarea"
                    rows="6"
                    maxlength="1500">
      </ion-textarea>
    </div>

    <app-image-adding
      (filesUpdated)="onFilesUpdated($event)">
    </app-image-adding>
  </form>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-button class="ion-margin button-bottom"
              color="dark" expand="block"
              [appLoaderInButton] = "onOKClick.bind(this)"
              [disabled]="form?.invalid || files.length === 0">
    {{ 'reply.addFiles.editTaskResult' | translate }}
  </ion-button>
</ion-footer>
