export const enum URL_DOWNLOAD_APP {
  ANDROID = 'https://play.google.com/store/apps/details?id=com.pyjam.dev',
  IOS = 'https://apps.apple.com/us/app/pyjam-content-creation/id6443738033'
}

export const MAX_FILES_COUNT: number = 10;

export const enum SIZE_LIMIT {
  SUPPORT = 100 * 1024 * 1024,    // 100  MB
  IMAGE = 100 * 1024 * 1024,      // 100  MB
  FILE = 500 * 1024 * 1024,       // 500  MB
  BIG_FILE = 1_500 * 1024 * 1024, // 1500 MB
}

export enum MAIL_TYPE {
  SUPPORT = 1,
  USER_REMOVAL = 2,
}

export enum NOTIFICATION_TYPE {
  MESSAGE = 1,
  COMMENT = 2,
  REPLY = 3,
  OFFER = 4,
  STATUS = 5,
  EXTRA_WORK = 6,
  TASK = 7,
  CHAT = 8,
  PAYMENT_SUCCEEDED = 9,
  ONBOARDING = 10,
  INACTIVITY_EXTRA_WORK = 11,
  INACTIVITY_DEADLINE = 12,
  INACTIVITY_OWNER = 13,
  DISPUTED = 14,
  OFFER_STATUS_ACCEPTED = 15,
  OFFER_STATUS_REJECTED = 16,
  DEADLINE = 17,
  EXTRA_WORK_REJECTED = 18,
  EXTRA_WORK_OFFER_EXTRA_PAY = 19,
  EXTRA_WORK_OWNER_APPROVE = 20,
  EXTRA_WORK_PAID = 21,
  EXTRA_WORK_WORKER_APPROVE = 22,
  EXTRA_WORK_PENDING = 23,
  EXTRA_WORK_WORKER_ADDED_RESULTS = 24,
  REPLY_DATA_UPDATED = 25,
  EXTRA_WORK_WAIT_PAY = 26,
  DISPUTE_CANCELED = 27,
  EXTRA_WORK_DELETED = 28,
  EXTRA_WORK_OWNER_APPROVE_RESULTS = 29,
  OFFER_CREATE_TASK = 30,
  PAYMENT_FAILED = 31,
  PAYMENT_CANCELED = 32,
  PAYMENT_AFTER_5_DAYS = 33,
  DISPUTED_TASK_FOR_TRUSTED_USER = 34,
  PAY_FOR_TRUSTED_USER = 35,
  PAYMENT_SUCCEEDED_REPLY_TIPS = 36,
  PAYMENT_SUCCEEDED_AVATAR_ORDER_TIPS = 37,
  BLACKLIST = 38,
  AVATAR_ORDER = 39,
  ACCOUNT_PAYPAL_INVALID = 40,
  AVATAR_ORDER_CANCELLED = 41,
  AVATAR_ORDER_ACCEPTED = 42,
  AVATAR_ORDER_REJECTED = 43,
  AVATAR_ORDER_DONE = 44,
  TASK_UNPUBLISHED = 45,
  TASK_WILL_BE_UNPUBLISHED_AFTER_5_DAYS = 46,
}
