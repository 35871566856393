import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TutorialPassedGuard implements CanActivate, CanLoad {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.conditionCheck();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.conditionCheck();
  }

  private conditionCheck(): boolean {
    if (typeof localStorage === 'undefined') return true;

    const intro: boolean = localStorage.getItem('introPassed') == '1';
    const passed: boolean = localStorage.getItem('tutorialControlPassed') == '1';
    const repeat: boolean = sessionStorage.getItem('tutorialControlRepeat') == '1';

    return !intro || !passed || repeat;
  }
}
