<ion-header class="black title-center">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-bottom ion-padding-end ion-padding-start">
  <h2 class="title">{{ (isPhone ? 'signConfirm.confirmNumber' : 'signConfirm.confirmEmail') | translate }}</h2>
  <div class="phone">{{ loginForm.get('login')?.value }}</div>
  <p class="subtitle">{{ (isPhone ? 'signConfirm.enterCodeFromSMS' : 'signConfirm.enterCodeFromEmail') | translate }}</p>
  <form [formGroup]="loginForm">
    <ion-input
        #codeInput
        type="tel"
        class="custom-ion-input sms-code"
        [ngStyle]="{'color': showError ? '#FD5F5E' : 'white'}"
        [formControlName]="'code'"
        [maxlength]="codeLength + 1"
        (ionChange)="smsCodeChanged($event)"
        (ionInput)="smsCodeInput($event)"
        (click)="onSmsInputClick()"
        (keydown)="onInputKeyDown($event)">
    </ion-input>

    <div class="error-code">
      <ng-container *ngIf="showError">
        <p class="error-code_message">
          {{ 'auth.invalid_code' | translate }}
        </p>
      </ng-container>
    </div>
  </form>
  <p *ngIf="!isCountdownOver" class="subtitle center">
    {{ 'signConfirm.repeatIn' | translate }}
    <countdown [config]="{leftTime: timeResendSmsCode, format: 'mm:ss'}" (event)="countdownEnded($event)"></countdown>
  </p>
  <p *ngIf="isCountdownOver" class="subtitle center" (click)="resendSmsCode()">
    <a>{{ 'signConfirm.sendCodeAgain' | translate }}</a>
  </p>
</ion-content>
