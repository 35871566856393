import {
  CategoryShortResponse,
  Client,
  DraftFileResponse,
  DraftRequest,
  DraftResponse,
  IndexTasksFiltersRequest,
  QuestionResponse,
  QuestionsByIdsRequest,
  TaskAnswerRequest,
  TaskAnswerUpdateRequest,
  TaskFileResponse,
  TaskRequest,
  TaskResponse,
  TasksDataResponse,
  UserResponse,
  UsersDataResponse
} from '../api-clients/pyjam/client';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExtendedClient, ITaskFileRequest, } from '../api-clients/pyjam/extended-client';
import { Observable, Subject } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { EventService } from '../services/event.service';
import { NavigationService } from '../services/navigation.service';
import { AddingFile } from '../ui-components/image-adding/image-adding.component';
import { FileUploadingService } from '../services/file-uploading.service';
import { AuthService } from '../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TaskController {

  public isLoadingInProgress = false;
  public COUNT_ON_PAGE = 50;
  public controllerMode: 'edit' | 'add' | 'draft';
  public closeUrl = 'tasks';
  public taskVm: TaskVm;
  public answersIdsForDelete = [];
  public answersEdited = 0;
  public filesIdsForDelete = [];
  public requestForFilteredTasks = {
    categories_id: [],
    answers_id: [],
    countOnPage: this.COUNT_ON_PAGE
  } as IndexTasksFiltersRequest;
  public questions: QuestionResponse[] = [];
  public exampleValues: string[];
  public filterVm: FilterVm;
  public progressInPercents: number;
  public startingPoint: StartingPoint;
  public specialistCount: string | number = '999+';
  public selectedTab: SelectedTab;
  public orderTabUrl: string;
  public isDisposed?: boolean;
  public isMySkill?: boolean = true;
  public categoryPushedSubject = new Subject<void>();
  public categoryIds = [];
  private offer: UserResponse;

  public get categoryPushed$(): Observable<void> {
    return this.categoryPushedSubject.asObservable();
  }

  public setOffer(offer: UserResponse) {
    this.offer = offer;
  }

  get checkOffer() {
    return !!this.offer;
  }

  public getOffer() {
    return this.offer;
  }

  get getFullName() {
    if (this.offer && this.offer.name && this.offer.surname) {
      return this.offer.surname + ' ' + this.offer.name;
    }
  }

  public answerPushedSubject = new Subject<any>();

  public get answerPushed$(): Observable<any> {
    return this.answerPushedSubject.asObservable();
  }

  public namePushedSubject = new Subject<void>();

  public get namePushed$(): Observable<any> {
    return this.namePushedSubject.asObservable();
  }

  public deadlinePushedSubject = new Subject<void>();

  public get deadlinePushed$(): Observable<void> {
    return this.deadlinePushedSubject.asObservable();
  }

  public pricePushedSubject = new Subject<void>();

  public get pricePushed$(): Observable<void> {
    return this.pricePushedSubject.asObservable();
  }

  public descriptionPushedSubject = new Subject<void>();

  public get descriptionPushed$(): Observable<void> {
    return this.descriptionPushedSubject.asObservable();
  }

  public summaryConfirmedSubject = new Subject<void>();

  public get summaryConfirmed$(): Observable<void> {
    return this.summaryConfirmedSubject.asObservable();
  }

  public vmFieldRemovedSubject = new Subject<void>();

  public get vmFieldRemoved$(): Observable<void> {
    return this.vmFieldRemovedSubject.asObservable();
  }

  private taskId: number;

  private _initialisationPromise: Promise<void>;
  private _initialisationPromiseResolve: (value: (PromiseLike<void> | void)) => void;

  public get initialisationPromise(): Promise<void> {
    return this._initialisationPromise;
  }

  constructor(
    private client: Client,
    private extendedClient: ExtendedClient,
    private activatedRoute: ActivatedRoute,
    private eventBus: EventService,
    private loadingService: LoadingService,
    private navService: NavigationService,
    private authService: AuthService,
    private fileUploadingService: FileUploadingService,
    private translate: TranslateService,
  ) {
    this._initialisationPromise = new Promise<void>((resolve, reject) => {
      this._initialisationPromiseResolve = resolve;
    });

    this.activatedRoute.queryParams.subscribe((param) => {
      if (param.min_price || param.max_price) {
        this.requestForFilteredTasks.min_price = param.min_price == 499 ? null : param.min_price;
        this.requestForFilteredTasks.max_price = param.max_price == 25001 ? null : param.max_price;
      }

      if (param.is_highRating) {
        this.requestForFilteredTasks.highRating = param.is_highRating;
      }

      if (param.is_deadline) {
        this.requestForFilteredTasks.is_deadline = param.is_deadline;
      }

      if (param.categories_id) {
        this.requestForFilteredTasks.categories_id = param.categories_id.split(',').map(string => +string);
        this.populateVmCategories().then();
      }
    });
  }

  public cleanController() {
    this.answersIdsForDelete = [];
    this.filesIdsForDelete = [];
    this.taskVm = new TaskVm();
    this.questions = [];
    this.closeUrl = 'tasks';
    this.selectedTab = null;
    this.answersEdited = 0;
    if (this.controllerMode == 'add') {
      this.eventBus.publish('task:add-finished');
    }
    this.isDisposed = true;
    this.offer = null;
  }

  public isGuestTask(): boolean {
    return !this.authService.isAuthenticated;
  }

  public isRollbackNeeded(): boolean {
    return this.isDisposed;
  }

  public initDisposed(): void {
    this.isDisposed = true;
    this._initialisationPromiseResolve(Promise.resolve());
  }

  public initAdd(closeUrl: string): void {
    this.taskVm = {
      category_id: null,
      category_name: null,
      name: null,
      deadline: false,
      deadline_at: '',
      description: '',
      price: null,
      answers: [],
      files: [],
    } as AddTaskVm;

    this.selectedTab = SelectedTab.Order;
    this.progressInPercents = 0;
    this.controllerMode = 'add';
    this.closeUrl = closeUrl == 'self-relate-tasks'
      ? closeUrl + '/my-tasks'
      : closeUrl;
    this.initStartingPoint();
    this.isDisposed = false;

    this._initialisationPromiseResolve(Promise.resolve());
  }

  public initEdit(taskId: number): void {
    this.taskId = taskId;
    this.controllerMode = 'edit';
    this.closeUrl = 'tasks/' + taskId;
    this.initStartingPoint();
    this.isDisposed = false;

    this._initialisationPromiseResolve(
      this.client.taskGet(this.taskId).toPromise()
        .then(async (result) => {
          this.taskVm = new EditTaskVm().fromTaskResponse(result.data);
          await this.loadQuestions(this.taskVm.category_id);
        }));
  }

  public initDraft(draftId: number, closeUrl: string): void {
    this.controllerMode = 'draft';
    this.closeUrl = (closeUrl == 'self-relate-tasks') ? closeUrl + '/my-tasks' : closeUrl;

    this.taskVm = {
      draftId: draftId
    } as AddTaskVm;

    this.initStartingPoint();
    this.isDisposed = false;

    const draftGetPromise = (this.authService.isAuthenticated && draftId > 0) ? this.client.draftGet((this.taskVm as AddTaskVm).draftId).toPromise() : Promise.resolve();
    this._initialisationPromiseResolve(draftGetPromise
      .then(async (draftResult) => {
        this.taskVm = (draftResult && draftId) ? this.taskVmFromDraftResponse(draftResult.data) : JSON.parse(localStorage.getItem('draft'));
        await this.loadQuestions(this.taskVm.category_id);
        await this.loadFiles(draftResult?.data.draftFiles || []);
        await this.getUsers(1, [this.taskVm.category_id]);
        this.controllerMode = 'add';
      })
      .catch((err) => {
        console.error(err);
        this.initDisposed();
      })
    );
  }

  private initStartingPoint(): void {
    if (this.controllerMode == 'add') {
      this.startingPoint = new StartingPoint();
      return;
    }

    var startingPoint = this.activatedRoute.snapshot.children[0]?.url[0]?.path;
    if (!startingPoint) {
      return;
    }

    if (this.controllerMode == 'edit' || this.controllerMode == 'draft') {
      switch (startingPoint) {
        case 'question': {
          this.startingPoint = {
            questionId: this.activatedRoute.snapshot.children[0]?.params?.id,
            isName: false,
            isDeadline: false,
            isPrice: false,
            isDescription: false
          };
          break;
        }
        case 'name': {
          this.startingPoint = {
            questionId: null,
            isName: false,
            isDeadline: false,
            isPrice: false,
            isDescription: false
          };
          break;
        }
        case 'deadline': {
          this.startingPoint = {
            questionId: null,
            isName: false,
            isDeadline: true,
            isPrice: false,
            isDescription: false
          };
          break;
        }
        case 'price': {
          this.startingPoint = {
            questionId: null,
            isName: false,
            isDeadline: false,
            isPrice: true,
            isDescription: false
          };
          break;
        }
        case 'description': {
          this.startingPoint = {
            questionId: null,
            isName: false,
            isDeadline: false,
            isPrice: false,
            isDescription: true
          };
          break;
        }
      }
      return;
    }
  }

  public isStartPoint(currentPoint: string, questionNumber?: number | null): boolean {
    return (currentPoint == 'question') && this.getFirstQuestion()?.id == questionNumber;
  }

  public async sendDraft(currentQuestionId: number): Promise<void> {
    const vm = this.taskVm as AddTaskVm;
    if (!this.authService.isAuthenticated && typeof localStorage !== 'undefined') {
      localStorage.setItem('draft', JSON.stringify(vm));
    }
    const pureVm = JSON.parse(JSON.stringify(vm));
    pureVm.files = [];
    vm.draftDate = new Date();
    if (!vm.draftId) {
      await this.clearUsersDrafts();
      const draftPostPromise = (this.authService.isAuthenticated) ? this.client.draftPost({
        json: JSON.stringify(pureVm)
      } as DraftRequest).toPromise() : Promise.resolve();
      await draftPostPromise.then(async (res) => {
        if (res) {
          vm.draftId = res.data.id;
          await vm.files.filter(file => !file.question_id || (file.question_id == currentQuestionId)).forEach(async (file) => {
            await this.fileUploadingService.draftAddFileToS3(res.data.id, file.question_id, file);
          });
        }
      })
        .catch(async ex => {
          console.error(ex);
          // await this.httpErrorHandlerService.handleHttpException(ex);
        });
    } else {
      const draftPutPromise = (this.authService.isAuthenticated) ? this.client.draftPut(vm.draftId, {
        json: JSON.stringify(pureVm)
      } as DraftRequest).toPromise() : Promise.resolve();
      await draftPutPromise.then(async (res) => {
        if (res) {
          await vm.files.filter(file => !file.question_id || (file.question_id == currentQuestionId)).forEach(async file => {
            await this.fileUploadingService.draftAddFileToS3(res.data.id, file.question_id, file);
          });
        }
      })
        .catch(async ex => {
          console.error(ex);
          // await this.httpErrorHandlerService.handleHttpException(ex);
        });
    }
  }

  public async clearUsersDrafts(): Promise<void> {
    if (this.authService.isAuthenticated) {
      var drafts = (await this.client.draftIndex().toPromise()).data;
      drafts.map(async (draft) => await this.client.draftDelete(draft.id).toPromise());
    }
  }

  public taskVmFromDraftResponse(draftResponse: DraftResponse): AddTaskVm {
    var taskVm = JSON.parse(draftResponse.json) as AddTaskVm;
    taskVm.draftId = draftResponse.id;
    return taskVm;
  }

  private async fileRequestFromBlob(fileResponse: TaskFileResponse) {
    let fileRequest = {
      fileName: fileResponse.file.name,
      id: fileResponse.file_id,
      question_id: fileResponse.question_id
    } as ITaskFileRequest;
    await this.extendedClient.fileDownloadByIdExtended(fileResponse.file_id)
      .then((res: Blob) => {
        let file: any = res;
        file.lastModifiedDate = new Date();
        file.name = fileResponse.file.name;
        fileRequest.file = file as File;
      })
      .catch(async ex => {
        // await this.httpErrorHandlerService.handleHttpException(ex);
      });

    return fileRequest;
  }

  public initialized(): boolean {
    return !!this.taskVm;
  }

  private async loadQuestions(categoryId: number): Promise<void> {
    if (this.controllerMode == 'add') {
      await this.client.questionIndex(categoryId, this.translate.currentLang).toPromise()
        .then((data) => {
          this.questions = [data?.data.find(question => question.is_start)];
        })
        .catch(async ex => {
          // await this.httpErrorHandlerService.handleHttpException(ex);
        });
    }
    if (this.controllerMode == 'edit' || this.controllerMode == 'draft') {
      let currentQuestionId = this.activatedRoute.snapshot.children[0]?.params?.id;
      if (currentQuestionId) {
        return this.client.questionGet(currentQuestionId, this.translate.currentLang).toPromise()
          .then((data) => {
            this.questions = [data.data];
          })
          .catch(async ex => {
            console.error(ex);
            // await this.httpErrorHandlerService.handleHttpException(ex);
          });
      }
    }
  }

  public async loadQuestion(questionId: number): Promise<void> {
    return this.client.questionGet(questionId, this.translate.currentLang).toPromise()
      .then((data) => {
        this.questions = this.questions.filter(question => question.id != questionId);
        this.questions.push(data.data);
      })
      .catch(async ex => {
        console.error(ex);
        // await this.httpErrorHandlerService.handleHttpException(ex);
      });
  }

  public getFirstQuestion(): QuestionResponse {
    return this.questions.find((question) => question.is_start == true);
  }

  public getQuestionById(questionId: number): QuestionResponse {
    return this.questions.find((question) => question.id == questionId);
  }

  private async loadFiles(files: DraftFileResponse[]): Promise<void> {
    const addingFiles: AddingFile[] = files.map(file => {
      const addingFile = new AddingFile();
      addingFile.id = file.id;
      addingFile.mimeType = file.file.type.name;
      addingFile.name = file.file.name;
      addingFile.size = file.file.size;
      addingFile.src = file.file.resizes[0].url;
      return addingFile;
    });
    await this.pushFiles(addingFiles);
  }

  public async pushCategory(categoryId: number, categoryName: string) {
    this.taskVm.category_id = categoryId;
    this.taskVm.category_name = categoryName;
    this.taskVm.answers = [];
    this.categoryPushedSubject.next();
  }

  public async pushName(name: string) {
    if (this.taskVm) {
      this.taskVm.name = name;
      await this.loadQuestions(this.taskVm.category_id);
      this.taskVm.nextQuestionId = this.getFirstQuestion()?.id;
      this.namePushedSubject.next();
    }
  }

  public pushPrice(price: number) {
    if (this.taskVm) {
      this.taskVm.price = price;
      this.pricePushedSubject.next();
    }
  }

  public pushDescription(description: string) {
    if (this.taskVm) {
      this.taskVm.description = description;

      if (this.controllerMode != 'edit') {
        this.loadingService.start().then();
        this.client.questionGetQuestionsByIds({
          ids: this.taskVm.answers.map(answer => answer.question_id)
        } as QuestionsByIdsRequest).toPromise()
          .then((response) => {
            response.data.forEach((question) => {
              this.taskVm.answers.find(answer => answer.question_id == question.id).text_question = question.text_question;
            });
          })
          .finally(() => {
            this.loadingService.stop().then();
          });
      }

      this.descriptionPushedSubject.next();
    }
  }

  public pushDeadline(deadlineTime: string, deadlineCheck: boolean) {
    if (this.taskVm) {
      this.taskVm.deadline_at = deadlineTime;
      this.taskVm.deadline = deadlineCheck;
      this.deadlinePushedSubject.next();
    }
  }

  public async pushAnswer(answersForPush: AnswerForPush[], question: QuestionResponse, updateUsers?: boolean): Promise<void> {
    let answers = answersForPush
      .filter(x => (x.value || x.value === '') || x.type_value || x.answer_id || x.answers_id || x.min_value || x.max_value)
      .map((answerForPush) => {
        return {
          value: answerForPush.value,
          type_value: answerForPush.type_value,
          question_id: question.id,
          answer_id: answerForPush.answer_id ?? undefined,
          answers_id: answerForPush.answers_id,
          min_value: answerForPush.min_value,
          max_value: answerForPush.max_value,
        };
      });
    let currentQuestion = this.getQuestionById(question.id);
    if (answers.length == 0) {
      this.taskVm.nextQuestionId = currentQuestion.is_skip_next_question_id ?? currentQuestion.next_question_id;
    } else if (currentQuestion.type_question == 'radio') {
      this.taskVm.nextQuestionId = currentQuestion.answers?.filter(answer => answer.id == answers[0]?.answer_id)[0]?.next_question_id ?? currentQuestion.next_question_id;
    } else {
      this.taskVm.nextQuestionId = currentQuestion.next_question_id;
    }

    if (this.taskVm.nextQuestionId) {
      await this.loadQuestion(this.taskVm.nextQuestionId).then();
    } else {
      this.taskVm.category_id = currentQuestion.category_id;
      this.taskVm.category_name = currentQuestion.category.name;
      this.categoryIds.push(currentQuestion.category_id);
    }

    if (this.controllerMode == 'edit') {
      answers = answers.map((answer) => {
        return {
          id: currentQuestion.type_question != 'multipleChoise'
            ? this.taskVm.answers.find(answer => answer.question_id == question.id)?.id
            : null,
          answer_id: answer.answer_id,
          answers_id: answer.answers_id,
          value: answer.value,
          type_value: answer.type_value,
          question_id: question.id,
          max_value: answer.max_value,
          min_value: answer.min_value,
        };
      });

      if (this.answersIdsForDelete.length == 0) {
        if (currentQuestion.type_question == 'radio') {
          this.answersIdsForDelete = await this.getAnswersTreeForDelete(question.id);
        } else {
          this.taskVm.nextQuestionId = null;
          if (currentQuestion.type_question == 'multipleChoise') {
            this.answersIdsForDelete = this.taskVm.answers.filter(answer => answer.question_id == question.id).map(answer => answer.id);
          }
        }
      } else {
        this.answersIdsForDelete = this.answersIdsForDelete.filter(id => id != this.taskVm.answers.find(answer => currentQuestion.type_question != 'multipleChoise' && answer.question_id == question.id)?.id);
      }

      this.answersEdited++;
    }

    this.removeAnswersByQuestionId(question.id);
    this.taskVm.answers.push(...answers);
    if (!updateUsers) {
      this.answerPushedSubject.next(answers);
    } else {
      await this.getUsers();
    }
    return Promise.resolve();
  }

  private async getAnswersTreeForDelete(questionId: number): Promise<number[]> {
    var question = this.getQuestionById(questionId);
    var answerTreeForDelete = [];

    do {
      question = (question.type_question != 'radio')
        ? this.getQuestionById(await this.getNextQuestionIdForNonRadioAnswer(question.id))
        : this.getQuestionById(await this.getNextQuestionIdForRadioAnswer(question.id));
      if (question) {
        answerTreeForDelete.push(...this.taskVm.answers.filter(answer => answer.question_id == question.id)?.map(answer => answer.id));
      }
    } while (!!question);

    return answerTreeForDelete;
  }

  private async getNextQuestionIdForNonRadioAnswer(questionId: number): Promise<number> {
    let currentQuestion = this.getQuestionById(questionId);
    let nextQuestionIdForRadioAnswer;
    if (currentQuestion.next_question_id) {
      await this.loadQuestion(currentQuestion.next_question_id)
        .then(() => {
        })
        .finally(() => {
          nextQuestionIdForRadioAnswer = currentQuestion.next_question_id;
        });
    }

    return nextQuestionIdForRadioAnswer;
  }

  private async getNextQuestionIdForRadioAnswer(questionId: number): Promise<number> {
    let answer = this.taskVm.answers.find(answ => answ.question_id == questionId);
    let currentQuestion = this.getQuestionById(questionId);
    let nextQuestionIdForRadioAnswer: number;
    if (answer?.answer_id != null) {
      let nextQuestionId = currentQuestion.answers.find(answ => answ.id == answer.answer_id).next_question_id;
      if (nextQuestionId) {
        await this.loadQuestion(nextQuestionId).then(() => {
          nextQuestionIdForRadioAnswer = nextQuestionId;
        });
      }
    } else {
      nextQuestionIdForRadioAnswer = currentQuestion.next_question_id;
    }

    return nextQuestionIdForRadioAnswer;
  }

  public pushFiles(files: AddingFile[], questionId = null) {
    this.taskVm.files = this.taskVm.files.filter(x => x.question_id !== questionId);
    (files as BriefAddingFile[]).forEach(file => file.question_id = questionId);
    this.taskVm.files.push(...files);
  }

  public summaryConfirm() {
    this.summaryConfirmedSubject.next();
  }

  public getTabUrl(activatedRoute: ActivatedRoute): string {
    return this.navService.getUrl(this.taskVm, this.selectedTab, activatedRoute);
  }

  public removeName() {
    this.taskVm.name = '';
    this.vmFieldRemovedSubject.next();
  }

  public removePrice() {
    this.taskVm.price = null;
    this.vmFieldRemovedSubject.next();
  }

  public removeDescription() {
    this.taskVm.description = '';
    this.vmFieldRemovedSubject.next();
  }

  public removeDeadline() {
    this.taskVm.deadline = false;
    this.taskVm.deadline_at = '';
    this.vmFieldRemovedSubject.next();
  }

  public removeAnswersByQuestionId(questionId: number) {
    this.taskVm.answers = this.taskVm.answers.filter(x => x.question_id !== questionId);
  }

  public pushFilterValue() {
    this.pushMinAndMaxPriceFilter();
    this.pushIsDeadlineFilter();
    this.pushFilteredCategory();
    this.pushIsHighRatingFilter();
  }

  public pushFilteredCategory() {
    this.requestForFilteredTasks.categories_id = this.filterVm.categories.map(value => value.id);
  }

  public pushIsDeadlineFilter() {
    this.requestForFilteredTasks.is_deadline = this.filterVm.is_deadline;
  }

  public pushIsHighRatingFilter() {
    this.requestForFilteredTasks.highRating = this.filterVm.is_highRating;
  }

  public pushMinAndMaxPriceFilter() {
    this.requestForFilteredTasks.min_price = this.filterVm.min_price == 499 ? null : this.filterVm.min_price;
    this.requestForFilteredTasks.max_price = this.filterVm.max_price == 25001 ? null : this.filterVm.max_price;
  }

  public pushReplyStatus(replyStatus: number | null) {
    this.requestForFilteredTasks.replyStatus = replyStatus;
  }

  public pushTaskStatus(taskStatus: number | null) {
    this.requestForFilteredTasks.taskStatus = taskStatus;
  }

  public pushIsMyTasks(isMyTask: boolean) {
    this.requestForFilteredTasks.isMy = isMyTask;
  }

  public async getFilteredTasks(page: number, search: string, withMyReplies?: boolean): Promise<TasksDataResponse | void> {
    this.requestForFilteredTasks.page = page;
    this.requestForFilteredTasks.search = search;
    this.requestForFilteredTasks.sort = 'created_at';
    this.requestForFilteredTasks.order = 'desc';
    if (withMyReplies !== null) {
      this.requestForFilteredTasks.myReplies = withMyReplies;
    }

    return await this.client.taskIndex(this.requestForFilteredTasks).toPromise()
      .then((result: TasksDataResponse) => result)
      .catch(async ex => {
        // await this.httpErrorHandlerService.handleHttpException(ex);
      });
  }

  public async cleanFilterVm() {
    this.requestForFilteredTasks = {
      categories_id: [],
      answers_id: [],
      countOnPage: this.COUNT_ON_PAGE,
      max_price: null,
      min_price: null,
    } as IndexTasksFiltersRequest;
    await this.initFilterVm();
  }

  public async initFilterVm() {
    this.filterVm = {
      categories: [],
      is_deadline: this.requestForFilteredTasks.is_deadline,
      is_highRating: this.requestForFilteredTasks.highRating,
      max_price: this.requestForFilteredTasks.max_price,
      min_price: this.requestForFilteredTasks.min_price,
      is_my_skill: this.isMySkill,
    };
    await this.populateVmCategories(this.requestForFilteredTasks.categories_id);
  }

  public changeMySkill(mySkill: boolean) {
    this.isMySkill = mySkill;
  }

  public async populateVmCategories(categoriesId?: number[]) {
    if (!categoriesId) {
      categoriesId = this.requestForFilteredTasks.categories_id;
    }

    if (categoriesId.length === 0) {
      this.filterVm.categories = [];
      return;
    }

    this.client.categoryAllCategory(true, true).toPromise()
      .then((response) => {
        this.filterVm.categories = response.data.filter((category) => categoriesId.includes(category.id));
      })
      .catch(async ex => {
        // await this.httpErrorHandlerService.handleHttpException(ex);
      });
  }

  public async getUsers(page = 1, categoryIds: number[] = undefined): Promise<UsersDataResponse | void> {
    if (categoryIds) {
      this.categoryIds.push(...categoryIds);
    } else if (!categoryIds && this.categoryIds.length > 0) {
      categoryIds = [...this.categoryIds];
    }
    return await this.loadingService.start().then(async () => {
      return await this.extendedClient.userIndexExtended(this.taskId, categoryIds, this.taskVm?.answers, undefined, undefined, page, undefined, undefined).toPromise()
        .then((result: UsersDataResponse) => {
          this.specialistCount = result.meta.total;
          return result;
        })
        .finally(() => this.loadingService.stop().then());
    });
  }

  public taskVmToTaskRequest(): TaskRequest {
    let taskRequest = {
      category_id: this.taskVm.category_id,
      name: this.taskVm.name,
      deadline: this.taskVm.deadline,
      deadline_at: this.taskVm.deadline_at,
      description: this.taskVm.description,
      answers: this.taskVm.answers,
      files: null, /* this.taskVm.files.map(addingFile => {
        return {
          file: addingFile.base64,
          name: addingFile.name,
          size: addingFile.size,
          question_id: addingFile.question_id,
        } as TaskFileRequest
      })*/
    } as TaskRequest;

    if (this.taskVm.price > 0) {
      taskRequest.price = this.taskVm.price;
    }

    return taskRequest;
  }
}

export class AnswerForPush {
  value?: string;
  type_value?: string | null = null;
  min_value?: string;
  max_value?: string;
  answer_id: number | null = null;
  answers_id?: number[] = [];
}

export class FilterVm {
  categories?: CategoryShortResponse[];
  min_price?: number;
  max_price?: number;
  is_deadline?: boolean;
  is_highRating?: boolean;
  is_my_skill?: boolean;
}

export class TaskVm {
  id: number;
  category_id: number;
  category_name: string;
  name: string;
  deadline: boolean;
  deadline_at: string;
  description: string;
  price: number;
  nextQuestionId?: number;
  files: BriefAddingFile[];
  answers: any;
}

export class AddTaskVm extends TaskVm {
  answers: TaskAnswerRequest[];
  draftId?: number;
  draftDate: Date;
  offerId?: number;
}

export class BriefAddingFile extends AddingFile {
  question_id?: number;
}

export class EditTaskVm extends TaskVm {
  answers: TaskAnswerUpdateRequest[];

  public fromTaskResponse(response: TaskResponse): EditTaskVm {
    return {
      category_id: response.category.id,
      name: response.name,
      deadline: response.deadline,
      deadline_at: response.deadline_at,
      description: response.description,
      price: response.price,
      answers: response.answers.map(answer => ({
        id: answer.id,
        question_id: answer.question_id,
        answer_id: answer.answer_id,
        value: answer.value,
        type_value: answer.type_value,
        answers_id: answer.answers_id,
      } as TaskAnswerUpdateRequest)),
      files: response.taskFiles.map(taskFile => {
        return {
          id: taskFile.file_id,
          question_id: taskFile.question_id,
          size: taskFile.file.size,
          src: (taskFile.file.resizes?.length > 0) ? taskFile.file.resizes[0].url : taskFile.file.url,
          name: taskFile.file.name,
          base64: (taskFile.file.resizes?.length > 0) ? taskFile.file.resizes[1].url : taskFile.file.url,
          mimeType: taskFile.file.type.name
        } as BriefAddingFile;
      })
    } as EditTaskVm;
  }
}

export class StartingPoint {
  questionId?: number;
  isName: boolean;
  isDeadline: boolean;
  isPrice: boolean;
  isDescription: boolean;
}

export enum TaskStatus {
  Unpublished = 1,
  Published,
  Done,
  Work
}

export enum SelectedTab {
  Order = 1,
  People,
}
