<form *ngIf="form" [formGroup]="form" class="custom-form ion-padding">
  <h2 class="title" style="margin-bottom: 15px">{{ 'tip.sum1' | translate }}</h2>
  <div class="price-container filed">
    <div class="input">
      <ion-input type="number"
                 maxlength="3"
                 inputMode="number"
                 formControlName="price"
                 class="custom-ion-input"
                 [placeholder]="'tip.sum' | translate"
                 (keydown)="onInputKeyDown($event)">
      </ion-input>
    </div>
  </div>
  <div *ngIf="form.touched && form.controls.price.errors?.required" class="error">
    {{ 'task.add.price.required' | translate }}
  </div>
  <div *ngIf="form.touched && form.controls.price.errors?.min" class="error">
    {{ 'tip.min' | translate }}
  </div>
  <div *ngIf="form.touched && form.controls.price.errors?.max" class="error">
    {{ 'tip.max' | translate }}
  </div>

  <ion-button (click)="onCancelClick()"
              style="margin-top: 35px"
              class="button-bottom"
              color="dark"
              fill="clear"
              expand="block">
    {{ 'tip.cancel' | translate }}
  </ion-button>
  <ion-button [disabled]="form?.invalid"
              (click)="onSubmitClick()"
              class="button-bottom"
              color="dark"
              expand="block">
    {{ 'tip.ok' | translate }}
  </ion-button>
</form>
